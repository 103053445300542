/**
 * @copyright Elmelo Ltd.
 */

/* eslint-disable */
import React from "react";

import { Container, Button, Form, Image, Alert, Modal } from "react-bootstrap";

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ContentLoader from "react-content-loader";
import IframeComm from "react-iframe-comm";

// import {
//     faPlusSquare,
//     faTrashAlt,
//     faCheck,
//     faCircle,
//     faAlignJustify,
//     faTimes,
//     faTruckMoving,
//     faShoppingBag,
//     faMoneyBill,
//     faArrowDown,
//     faCreditCard,
//     faPlus,
//     faMinus,
//     faTicketAlt,
//     faDotCircle
// } from '@fortawesome/free-solid-svg-icons'

import { useSelector, useDispatch } from "react-redux";
// import { connect } from "react-redux";
// import * as actions from "../../rdx/actions";

import { Lambda, Utils } from "../../api";
// import elml_cfg from '../../_config/elml_cfg'

// import color from '../_common/colors.json'
import "../../css/_common.css";

interface ICheckoutConfirmation {
	pi: any;

	OnClose?: any;
	OnConfirm?: any;

	ShowError?: any;
} // ICheckoutConfirmation

const Checkout_Confirmation = (props: ICheckoutConfirmation) => {
	const rdxCfg = useSelector((state: any) => state.__cfg);

	const [isModal, IsModal] = React.useState(true);
	const [isLoading, IsLoading] = React.useState(false);
	const [isAuth, IsAuth] = React.useState(false);

	const [paymIntent, PaymIntent] = React.useState<any>(null);
	const [authUrl, AuthUrl] = React.useState("");

	/**
	 */
	React.useEffect(() => {
		// console.log("Checkout_Confirmation : useEffect : ");

		// console.log( 'Checkout_Confirmation: props: ', props )

		if (!props.pi) {
			return undefined;
		}

		Init()
			// .then(data => {})
			.catch(console.warn);
	}, [props.pi]);

	//
	const OnMsg = async event => {
		if ("3DS-authentication-complete" !== event.data) {
			return {};
		}

		ConfirmRetr().catch(console.warn);
	};

	// //
	// const Close = () => {
	// 	IsModal( false )

	// 	props.OnClose?.()
	// }; // Close

	const ConfirmRetr = async () => {
		try {
			IsLoading(true);

			//
			const aws_lambda = new Lambda({});

			const p_lambda = {
				stage: rdxCfg.stage,
				usr: "beta",
				actType: "pay",
				act: "card:confirm-retr",
				pi: paymIntent.id,
				client_secret: paymIntent.client_secret
				// return_url: return_url,
			};

			const { resp } = await aws_lambda.Invoke(p_lambda, rdxCfg.lambda("beta", rdxCfg.stage));

			IsLoading(false);
			IsModal(false);

			props.OnClose?.();
			props.OnConfirm?.(resp);

			//
			return {};
		} catch (err) {
			console.error("Checkout_Confirmation: ConfirmRetr: err: ", err);

			IsLoading(false);

			return { err };
		}
	}; // ConfirmRetr

	//
	const Init = async () => {
		try {
			// confirm payment intent ...
			const cfg = rdxCfg;
			const aws_lambda = new Lambda({});

			// console.log("window.location.href: ", window.location.href);

			const href_words = window.location.href.split("/");

			// console.log("href_words: ", href_words);

			const href_joined = href_words.slice(0, -1).join("/");

			// console.log("href_joined: ", href_joined);

			const p_lambda = {
				stage: cfg.stage,
				usr: "beta",
				actType: "pay",
				act: "card:confirm",
				pi: props.pi.id,
				// return_url: return_url,
				// return_url: window.location.href,
				return_url: [href_joined, "paymentredirect"].join("/")
			};

			// console.log("Checkout_Confirmation : p_lambda : ", p_lambda);

			const resp = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));

			// console.log("Checkout_Confirmation : resp : ", resp);

			if (resp.errorMessage) {
				console.error("Checkout_Confirmation: Init : err : ", resp);
				// throw new Error( resp.errorMessage )

				props.OnClose?.();

				props.ShowError?.(resp.errorMessage);
			}

			if ("succeeded" === resp.resp.status || "requires_payment_method" === resp.resp.status) {
				props.OnClose?.();
				props.OnConfirm?.(resp.resp);

				return {};
			}

			if ("requires_action" === resp.resp.status) {
				// console.log("requires_action: resp.resp: ", resp.resp);
				PaymIntent(resp.resp);
				AuthUrl(resp.resp.next_action.redirect_to_url.url);
				IsAuth(true);
				return {};
			}

			PaymIntent(resp.resp);

			return {};
		} catch (err) {
			console.error("Checkout_Confirmation: Init: err: ", err);

			return Promise.reject(err);
		}
	}; // Init

	/**
	 */
	return (
		<div>
			{isModal && (
				<Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={true}
					// onHide={() => this.props.hideView()}
					animation={true}
					style={{ zIndex: 1111111111 }}>
					<>
						{paymIntent && !isLoading ? (
							<IframeComm
								attributes={{
									// src: "/checkout_confirmation.html",
									src: authUrl,
									width: "450px",
									height: "450px"
								}}
								handleReceiveMessage={OnMsg}
							/>
						) : (
							<div className="overlayContainer">
								<ContentLoader height={160} width={200} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
									<rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
									<rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
									<rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
									<rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
									<rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
									<rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
								</ContentLoader>
							</div>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}; // Checkout_Confirmation

/**
 */
export default Checkout_Confirmation;
